@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

body {
  font-family: "Kalam", sans-serif;
  font-size: large;
  color: #0e1f50;
  margin: 0;
}

main {
  min-height: calc(100vh - 14rem);
  margin-top: 3.6rem;
}

@media (min-width: 40rem) {
  main {
    margin-top: 4.5rem;
  }
}

button > svg {
  margin-right: 5px;
}
