#hero-banner {
  background: linear-gradient(180deg, rgba(249, 248, 246, 0) 5%, #f9f8f6 96%),
    url("assets/hero.jpeg") left 20% bottom 60% / cover no-repeat border-box,
    #f9f8f6;
  background-size: cover;
}

#who-i-am {
  background: linear-gradient(to top, #f9f8f6, transparent), #f9f8f6;
}

.who-i-am__card {
  display: block;
  padding: 20px 10px;
  text-align: center;
}

.who-i-am__video {
  width: 100%;
}

@media (min-width: 40rem) {
  .who-i-am__cards {
    width: 80%;
  }

  .who-i-am__card {
    display: inline-block;
    vertical-align: middle;
    width: 35%;
    padding: 20px 30px;
    box-shadow: 14px 14px 60px -10px #cec4bc;
  }
}

#photos {
  background: linear-gradient(to bottom, #f9f8f6, transparent), #f9f8f6;
}

.photos__gallery {
  text-align: center;
  padding: 60px 0;
}

.photos__container {
  display: block;
}

.photos__container img {
  width: 100%;
  height: auto;
  position: static;
  box-shadow: 14px 14px 60px 14px #cec4bc;
}

@media (min-width: 40rem) {
  .photos__gallery {
    height: 34.375rem;
  }

  .photos__container {
    height: 15.625rem;
    display: inline-block;
    position: relative;
    width: 50rem;
  }

  .photos__container img {
    position: absolute;
    height: 100%;
    width: auto;
  }

  #photos__1 {
    top: 80px;
    left: 100px;
    z-index: 4;
  }

  #photos__2 {
    right: 10px;
    top: 100px;
    z-index: 6;
  }

  #photos__3 {
    right: 150px;
    top: 0px;
    z-index: 5;
  }

  #photos__4 {
    top: 220px;
    left: 340px;
    z-index: 3;
  }
}

#social-selling {
  background: linear-gradient(to top, #e9895b, #f9f8f6), #e9895b;
}

.social-selling__desc {
  display: block;
  width: 100%;
  margin-right: 24px;
}

.social-selling__bgimage {
  background: linear-gradient(
      to top,
      rgba(233, 137, 91, 0.9),
      rgba(249, 248, 246, 0.1)
    ),
    url("assets/social-selling.jpeg") left 10% bottom 20% / cover no-repeat
      border-box;
  display: block;
  width: 100%;
  /* max-width: 40rem; */
  height: 37.5rem;
}

@media (min-width: 40rem) {
  #social-selling > div {
    width: 80%;
  }

  .social-selling__desc {
    display: inline-block;
    vertical-align: middle;
    width: 25%;
  }

  .social-selling__bgimage {
    display: inline-block;
    vertical-align: middle;
    vertical-align: middle;
    width: 70%;
  }
}

#contacts {
  background: url("assets/brushes.png") right 10% top 20% / contain no-repeat
    border-box padding-box;
  text-align: center;
}

.contacts__sconti {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 1.3rem;
}

.contacts__sconti:hover {
  font-weight: bold;
}

@media (min-width: 40rem) {
  #contacts {
    background: url("assets/brushes.png") right 10% bottom 10% / contain
      no-repeat border-box padding-box;
  }
}
